<div #tasknote tabindex="0"
    class="sticky-note task-scale-{{ scale }} {{ hoverClick ? 'hover-click' : '' }}"
    [class.hover]="hover" [class.editable]="editable" [class.slide]="slide"
    [class.dragndrop]="dragndrop" [class.selected]="selected" [class.draggable]="dragndrop"
    [style]="customStyles" [style.display]="hide ? 'none' : 'block'">

    <div class="sticky-note-inner">
        <!-- note that some js event handlers are used as changedetection is suspended for performance reasons during drag'n'drop -->
        <div class="sticky-note-overlay" [class.allowgrips]="allowGrips"
            (click)="onMouseEvents('click', $event)"
            [draggable]="dragndrop" [attr.data-dragndrop]="dragndroplinks"
            (dragstart)="onDragEvents('dragstart', $event)"
            (dragend)="onDragEvents('dragend', $event)"
            (dragenter)="onDragEvents('dragenter', $event)"
            (dragleave)="onDragEvents('dragleave', $event)" 
            (drop)="onDragEvents('drop', $event)"
            ondragover="if (this.getAttribute('draggable') == 'true' || (this.getAttribute('data-dragndroplinks') == 'true' && event.dataTransfer.effectAllowed == 'link')) { event.preventDefault(); }"
            (mouseenter)="onMouseEvents('mouseenter', $event)" (mouseleave)="onMouseEvents('mouseleave', $event)"
            ondragenter="var n = this.parentNode.parentNode; if (!n.classList.contains('hover')) { n.classList.add('hover'); }" 
            ondragleave="var n = this.parentNode.parentNode; if (n.classList.contains('hover')) { n.classList.remove('hover'); }"
            ondragend="var n = this.parentNode.parentNode; if (n.classList.contains('hover')) { n.classList.remove('hover'); }"
            ondrop="var n = this.parentNode.parentNode; if (n.classList.contains('hover')) { n.classList.remove('hover'); }">

            <div class="left-grip" data-griptype="child" [draggable]="dragndrop && allowGrips"
                (dragstart)="onGripDragEvents('dragstart', $event)" (dragend)="onGripDragEvents('dragend', $event)"
                (dragenter)="onGripDragEvents('dragenter', $event)" (dragleave)="onGripDragEvents('dragleave', $event)" 
                (dragover)="onGripDragEvents('dragover', $event)" (drop)="onGripDragEvents('drop', $event)">
            </div>
            <div class="right-grip" data-griptype="parent" [draggable]="dragndrop && allowGrips"
                (dragstart)="onGripDragEvents('dragstart', $event)" (dragend)="onGripDragEvents('dragend', $event)"
                (dragenter)="onGripDragEvents('dragenter', $event)" (dragleave)="onGripDragEvents('dragleave', $event)" 
                (dragover)="onGripDragEvents('dragover', $event)" (drop)="onGripDragEvents('drop', $event)">
            </div>
            
            <button type="button" *ngIf="editable || readonly" class="note-edit" (click)="$event.stopPropagation();menu.toggle($event)">
                <i class="ds-icon-default-chevron-down"></i>
            </button>
        </div>

        <div class="note-container" [style.backgroundColor]="swimlaneTeamColour" [style.color]="swimlaneTeamTextColour" >
            <div *ngIf="type == 0" class="note-type-activity">
                <div class="note-top-row">
                    <div>
                        <span class="annotation">{{ 'TaskResponsibility' | localize }}:</span>
                        <span *ngIf="isNullOrUndefinedOrEmptyString(swimlaneTeamTitle)" class="content">{{ taskResponsibility }}</span>
                        <span *ngIf="!isNullOrUndefinedOrEmptyString(swimlaneTeamTitle)" class="content">{{ swimlaneTeamTitle }}</span>
                    </div>
                    <div>
                        <span class="annotation">{{ 'TaskTeamSize' | localize }}:</span>
                        <span class="content">{{ taskTeamSize }}</span>
                    </div>
                    <div>
                        <span class="annotation">{{ 'TaskDate' | localize }}/<br />{{ 'TaskDuration' | localize }}:</span>
                        <span class="content">{{ taskDate | luxonFormat: 'D' }}<br /><b>{{ formattedDuration }}</b></span>
                    </div>
                </div>
                <div class="note-main">
                    <div class="note-row">
                        <span class="annotation">{{ 'TaskTitle' | localize }}:</span>
                        <span class="content"><b>{{ taskTitle }}</b></span>
                    </div>
                    <div class="note-row">
                        <span class="annotation">{{ 'TaskLocation' | localize }}/{{ 'TaskWorkArea' | localize }}:</span>
                        <!-- <span class="content">{{ taskLocation }} <span [hidden]="isNullOrUndefinedOrEmptyString(taskLocation) || isNullOrUndefinedOrEmptyString(taskWorkArea)">/</span> {{ taskWorkArea }}</span> -->
                        <span class="content">{{ taskLocationTitle }} <span [hidden]="isNullOrUndefinedOrEmptyString(taskLocationTitle) || isNullOrUndefinedOrEmptyString(taskWorkArea)">/</span> {{ taskWorkArea }}</span>
                    </div>
                    <div class="note-row">
                        <span class="annotation">{{ 'TaskLinkedActivity' | localize }}:</span>
                        <span class="content">{{ linkedActivityName }}</span>
                    </div>
                </div>
            </div>
            
            <div class="strike-through" *ngIf="strikethrough"></div>
            
            <div class="at-risk" *ngIf="atRiskOrBlocked">
                <i class="ds-icon-default-warning"></i>
            </div>

            <div class="state-icon" [class.light-icon]="useLightStateIcon" 
                *ngIf="showSnapshotComparisonIcons && snapshotComparisonState == TaskSnapshotComparisonStateEnum.Added">
                <i class="far fa-plus-square"></i>
            </div>
            <div class="state-icon" [class.light-icon]="useLightStateIcon" 
                *ngIf="showSnapshotComparisonIcons && snapshotComparisonState == TaskSnapshotComparisonStateEnum.Edited">
                <i class="fas fa-cog"></i>
            </div>

            <div class="baseline-progress" [hidden]="taskProgressHidden"><!--</div> *ngIf="showSnapshotComparisonIcons && taskProgress != snapshotComparisonProgress">-->
                <div class="baseline-progress-bar" [hidden]="!showSnapshotComparisonIcons || taskProgress == snapshotComparisonProgress"
                    [style.width.%]="snapshotComparisonProgress"></div>
                <div class="current-progress-bar" [style.width.%]="taskProgress"></div>
            </div>
        </div>

        <div class="select-checkbox-container" *ngIf="showSelectCheckbox" (click)="toggleSelection($event)">
            <div class="select-checkbox">
                <i class="pi pi-check" [class.hidden]="!selected"></i>
            </div>
        </div>
    </div>

    <!-- ondragover="if (this.getAttribute('data-dragndroplinks') == 'true' && event.dataTransfer.effectAllowed == 'link') { event.preventDefault(); }" -->
    <!--{{ taskTheme }} (dragover)="onDragEvents('dragover', $event)"-->
    <!--  -->
</div>
<p-menu #menu [popup]="true" appendTo="body" [model]="menuitems"></p-menu>